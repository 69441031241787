import { useEventListener } from '@vueuse/core';

function toggleClasses(element: Element | null, removeClass: string, addClass: string) {
    if (element) {
        element.classList.remove(removeClass);
        element.classList.add(addClass);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const navbar: HTMLElement | null = document.querySelector('.navbar-dark');
    const topMenuPromotional: HTMLDivElement = document.querySelector('.top-menu-promotional-bar');
    const topMenu: HTMLDivElement = document.querySelector('.top-menu');
    const navbarDark: HTMLElement = document.querySelector('.navbar-dark');
    const alphabetWrapper: HTMLDivElement = document.querySelector('.alphabet-wrapper');

    const toggleThreshold = 50;
    let didScroll = false;
    let lastScrollTop = 0;

    function hasScrolled(navbarHeight: number) {
        enum headerClassEnum {
            NAV_DOWN = 'nav-down',
            NAV_UP = 'nav-up',
            WRP_DOWN = 'wrp-down',
            WRP_UP = 'wrp-up',
        }

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (Math.abs(lastScrollTop - scrollTop) <= toggleThreshold) {
            return;
        }

        if (scrollTop > lastScrollTop && scrollTop > navbarHeight) {
            toggleClasses(topMenuPromotional, headerClassEnum.NAV_DOWN, headerClassEnum.NAV_UP);
            toggleClasses(topMenu, headerClassEnum.NAV_DOWN, headerClassEnum.NAV_UP);
            toggleClasses(navbarDark, headerClassEnum.NAV_DOWN, headerClassEnum.NAV_UP);
            toggleClasses(alphabetWrapper, headerClassEnum.WRP_DOWN, headerClassEnum.WRP_UP);
        } else if (scrollTop + window.innerHeight < document.documentElement.offsetHeight) {
            toggleClasses(topMenuPromotional, headerClassEnum.NAV_UP, headerClassEnum.NAV_DOWN);
            toggleClasses(topMenu, headerClassEnum.NAV_UP, headerClassEnum.NAV_DOWN);
            toggleClasses(navbarDark, headerClassEnum.NAV_UP, headerClassEnum.NAV_DOWN);
            toggleClasses(alphabetWrapper, headerClassEnum.WRP_UP, headerClassEnum.WRP_DOWN);
        }

        lastScrollTop = scrollTop;
    }

    if (navbar) {
        const navbarHeight = navbar.offsetHeight;

        useEventListener(window, 'scroll', () => {
            didScroll = true;
        }, { passive: true });

        const checkScroll = () => {
            if (didScroll) {
                const mobileDropdown: HTMLDivElement = document.querySelector('.mobile-search-dropdown');
                const ACTIVE_CLASS = 'active';

                if (!mobileDropdown?.classList.contains(ACTIVE_CLASS)) {
                    hasScrolled(navbarHeight);
                }
                didScroll = false;
            }
            requestAnimationFrame(checkScroll);
        };

        requestAnimationFrame(checkScroll);
    }
});
